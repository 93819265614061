import React from "react"

import Header from "../../components/header"
import HeaderMobile from "../../components/headerMobile"

import { Container, Content, Text } from "./styles"

export default function Terms() {
  return (
    <Container fluid>
      <Header title="Termos de Uso e Responsabilidade" />
      <HeaderMobile title="Termos de Uso e Responsabilidade" />
      <Content>
        <Text>
          <p>Prezado cliente,</p>
          <p>
            Por favor, leia atentamente os termos e condições abaixo estipulados
            (“Termos”) para que Você possa usufruir da plataforma Veriza
            (“Plataforma”) e de certos serviços disponibilizados por meio desta.
            A Plataforma é de titularidade da Veriza correspondente bancario
            ltda, empresa inscrita no CNPJ/MF sob no 36.026.347/0001- 50, com
            endereço no EDIFÍCIO ÍON - SGAN QD 601 CJ H SALA 54 SS1- PARTE 29,
            Asa Norte, Brasília/DF e e-mail:
            contato@Veriza.com.br.
          </p>
          <p>
            Caso você não concorde com quaisquer dispositivos dos termos de uso,
            não utilize a Plataforma, pois a sua utilização representa sua
            aceitação integral e incondicional destes Termos. Integram os
            presentes Termos a Política de Privacidade e a Política de Avaliação
            de Crédito da Plataforma.
          </p>
          <p>
            <strong>Objetivos da Plataforma:</strong>
            <br></br> A Plataforma Veriza tem por objetivo disponibilizar um
            ambiente eletrônico por meio do qual você pode expor suas propostas
            para captação de recursos (“Pedidos de Empréstimo”), as quais serão
            submetidas à avaliação da Veriza, e, eventualmente, obter tais
            recursos de instituições financeiras parceiras da Plataforma
            (“Instituição Financeira”), que são responsáveis pela concessão do
            crédito aos Tomadores (conforme abaixo definido). A Plataforma não
            garante a conclusão das transações pretendidas por você.
          </p>
          <p>
            Tomadores são as pessoas físicas ou jurídicas que, por meio de
            acesso à Plataforma, encaminharão Pedidos de Empréstimo (e serão
            aqui referidos como “Você”, “Tomador” ou “Tomadores”). A Veriza
            encaminhará os Pedidos de Empréstimo para análise e aprovação das
            Instituições Financeiras parceiras e, sujeito ao atendimento dos
            requisitos previstos nesses Termos e demais regras da Plataforma,
            incluindo, sem limitação, os Termos de Uso, gerará uma proposta de
            crédito (“Proposta de Crédito”).
          </p>
          <p>
            Ao se cadastrar na Plataforma, o Tomador reconhece e concorda que
            todas as informações inseridas na Plataforma serão analisadas pela
            Veriza em consonância com a Política de Avaliação de Crédito e a
            Política de Privacidade da Veriza e poderão gerar uma Proposta de
            Crédito que, uma vez aceita pelo Tomador, de acordo com os Termos de
            Uso, será encaminhada à Instituição Financeira para aprovação e,
            caso aprovada, originação do crédito.
          </p>
          <p>
            <strong>Cadastro de Tomadores:</strong>
            <br></br> os serviços da Plataforma só podem ser utilizados por
            pessoas legalmente capazes. Assim, ao se cadastrar, Você declara,
            sob as penas da lei, ser maior de 18 (dezoito) anos e estar em pleno
            gozo de seus direitos civis. Se Você está representando alguma
            pessoa jurídica ou pessoa física, Você declara, sob as penas da lei,
            ter plenos poderes de representação, devendo enviar junto com o
            Pedido de Empréstimo os documentos que comprovem tais poderes.
          </p>
          <p>
            O Tomador declara e garante que as afirmações a seguir são
            verdadeiras e assim permanecerão durante todo o prazo de vigência
            destes Termos: (i) o Tomador possui plena capacidade e legitimidade
            para celebrar os presentes Termos; (ii) a celebração desses Termos e
            o cumprimento das obrigações pelo Tomador não violam qualquer
            disposição contida nos seus documentos societários, se aplicável;
            (iii) no melhor conhecimento do Tomador, a celebração desses Termos
            não viola qualquer lei, regulamento, decisão judicial,administrativa
            ou arbitral; e (iv) o Tomador está devidamente autorizado, nos
            termos dos seus atos constitutivos em vigor, se aplicável, e a
            celebração desses Termos não viola e nem está em conflito com
            qualquer contrato do qual seja parte. Cada Tomador deve ter apenas
            um cadastro junto à Plataforma. Caso verifiquemos que Você possui
            cadastro duplicado, cancelaremos todos os seus cadastros e Você
            poderá ser impedido de se cadastrar novamente em nossos sistemas.
          </p>
          <p>
            Apenas será confirmado o cadastramento do interessado que preencher
            todos os campos do cadastro. O Tomador deverá completá-lo com
            informações exatas, precisas e verdadeiras. Caso tomemos ciência de
            qualquer dado cadastral falso ou omisso, cancelaremos o seu
            cadastro. Você deverá arcar isoladamente com qualquer declaração
            falsa ou omissa que possa gerar prejuízos à Veriza ou a terceiros.
            Caberá ao Tomador informar à administração da Plataforma, através da
            interface própria da Plataforma, acerca de quaisquer alterações nas
            informações fornecidas quando do respectivo cadastro, devendo
            mantê-las atualizadas.
          </p>
          <p>
            Em caso de constatação de infração do Tomador aos presentes Termos
            ou violação da lei, a exclusivo critério da Veriza, o cadastro do
            Tomador será bloqueado, assim como seu acesso à Plataforma e às suas
            informações, independentemente de prévia notificação.
          </p>
          <p>
            Dados para Pedidos de Empréstimo: ao realizar o cadastro, o Tomador
            deverá informar os dados necessários para (i) a emissão de um Pedido
            de Empréstimo e (ii) a avaliação de crédito do Tomador.
          </p>
          <p>
            O Tomador declara estar ciente e concorda que todos os dados
            informados na Plataforma, serão compartilhados com as Instituições
            Financeiras, responsáveis pela concessão ou não do crédito. Salvo em
            caso de reorganização societária, fusão, incorporação, transferência
            de controle, ou alienação substancial dos ativos da Veriza, em que a
            Veriza poderá transferir, dar acesso ou alienar as informações de
            cadastro dos Tomadores sem necessidade de prévia aprovação ou
            notificação destes ou em caso de outras hipóteses estabelecidas nos
            presentes Termos ou na Política de Privacidade, em nenhuma outra
            hipótese será permitida a cessão, alienação ou outra forma de
            transferência a terceiros das informações do cadastro do Tomador.
            Você declara, neste ato, estar ciente sobre a possibilidade de
            ocorrência da transferência das informações de cadastro nas
            hipóteses acima descritas, bem como estar de acordo com esta
            situação.
          </p>
          <p>
            Sem prejuízo do disposto acima, o Tomador reconhece e concorda que
            poderá ser inscrito como inadimplente nos serviços de proteção ao
            crédito, sofrer protesto ou execução na esfera judicial, em caso de
            inadimplência do Tomador sob qualquer Proposta de Crédito, CCB e
            documentos correlatos. Assim, o Tomador reconhece e concorda que o
            inadimplemento de suas obrigações poderá ensejar o registro da
            dívida junto aos órgãos de proteção ao crédito e entidades
            pertinentes, incluindo, sem limitação, Boa Vista Serviços S.A.,
            Serasa S.A. e outros bureaus de crédito similares, os quais
            comunicarão tal fato à parte inadimplente por e-mail e/ou carta com
            aviso de recebimento para os endereços estão cadastrados na
            Plataforma, de acordo com a legislação aplicável.
          </p>
          <p>
            <strong>Dados para Pedidos de Empréstimo:</strong>
            <br></br> ao se cadastrar na Plataforma como Tomador, Você autoriza
            a Veriza e as Instituições Financeiras a obter suas informações de
            crédito de uma ou mais instituições, no Brasil ou no exterior,
            incluindo, sem limitação, as informações registradas no Sistema de
            Informações de Crédito (“SCR”) do Banco Central do Brasil (“BACEN”),
            junto a bureaus de crédito e quaisquer sistemas de pesquisa e
            análise, ou serviços e/ou repositórios de dados públicos ou privados
            aos quais a Veriza tenha acesso e desde que obtida sua devida
            autorização, por meio destes Termos. A Veriza irá conferir e checar
            todas as suas informações inseridas na Plataforma, por meio de dados
            constantes em repositórios públicos ou privados, nos termos acima. A
            Veriza realizará as seguintes atividades, dentre outras necessárias
            à consecução dos objetivos da Plataforma: (i) verificação da
            autenticidade de todos os dados cadastrais do Tomador; (ii)
            avaliação de crédito do Tomador; e (iii) classificação de crédito e
            posterior emissão da Proposta de Crédito, conforme o caso. Você
            autoriza a Veriza a compartilhar suas informações com outras
            entidades de seu grupo econômico e com empresas parceiras da Veriza,
            apenas para auxiliar a Veriza na realização das atividades previstas
            nesse item. A Veriza não comercializa suas informações com
            terceiros, e todas as empresas parceiras da Veriza que tiverem tido
            acesso às suas informações tratarão essas informações de modo
            confidencial. A VERIZA NÃO GARANTE AO TOMADOR UM DESEMPENHO
            SATISFATÓRIO NA AVALIAÇÃO DE CRÉDITO, TAMPOUCO O SUCESSO DE
            APROVAÇÃO OU CONTRATAÇÃO DE CRÉDITO. A APROVAÇÃO DO CRÉDITO É DE
            RESPONSABILIDADE EXCLUSIVA DAS INSTITUIÇÕES FINANCEIRAS PARCEIRAS DA
            VERIZA.
          </p>
          <p>
            <strong>Esclarecimentos sobre o SCR:</strong>
            <br></br> o SCR é um sistema administrado pelo BACEN, por meio do
            qual determinadas instituições devem remeter informações relativas a
            operações de crédito por elas realizadas ou adquiridas –
            independentemente do seu adimplemento. Após a remessa, o BACEN
            consolida as informações recebidas e as disponibiliza para consulta
            das instituições autorizadas pela regulamentação vigente.
          </p>

          <p>
            O registro de informações acerca de operações de crédito no SCR visa
            (i) favorecer a supervisão do BACEN sobre as carteiras de crédito
            das instituições que concedem crédito, promovendo mecanismos mais
            eficientes para a gestão de riscos, detecção e prevenção de crises
            no sistema financeiro, e (ii) permitir que os tomadores de crédito
            acompanhem as informações referentes às suas operações de crédito
            com transparência.
          </p>
          <p>
            Assim, ao se cadastrar na Plataforma, Você também autoriza
            expressamente que (i) a Veriza e as Instituições Financeiras acessem
            as informações registradas no SCR relativas às operações de crédito
            realizadas por Você; e (ii) que o BACEN disponibiliza o acesso a
            tais informações às instituições que podem consultar o SCR nos
            termos da regulamentação vigente e que adquiram ou recebam em
            garantia, ou manifestem interesse de adquirir ou de receber em
            garantia, total ou parcialmente, operações de crédito em que Você é
            o devedor.
          </p>

          <p>
            <strong>Finalidade do uso das informações:</strong>
            <br></br> o acesso pela Veriza e pelas Instituições Financeiras às
            informações compartilhadas no SCR tem o objetivo de (i) melhorar a
            capacidade de avaliação de crédito do Tomador por meio da mensuração
            de riscos relativos ao seu nível de endividamento, perfil e
            capacidade de pagamento; e (ii) viabilizar a correta classificação
            de crédito do Tomador para emissão de Proposta de Crédito adequada
            ao seu perfil. Forma de consulta às informações Além das
            instituições participantes do SCR, Tomadores também podem acessar o
            SCR de forma eletrônica e gratuita mediante cadastro no sistema, por
            meio do link https://www.bcb.gov.br/pt-br/#!/p/REGISTRATO.
          </p>
          <p>
            Procedimentos para contestação de informações: caso Você verifique
            que os seus dados estão incorretos, Você deverá, primeiramente,
            solicitar à instituição competente que efetue a respectiva
            retificação, já que somente as instituições responsáveis pela
            inclusão das informações no SCR podem alterá-las ou excluí-las do
            sistema.
          </p>
          <p>
            Se não houver consenso com a instituição responsável sobre a
            retificação solicitada, Você poderá registrar uma reclamação na
            Central de Atendimento ao Público do BACEN ou até mesmo demandar a
            alteração ou exclusão dos dados judicialmente. Para solicitar a
            correção, exclusão ou registro de medidas judiciais, ou apresentar
            discordâncias em relação às informações incluídas no SCR
            relacionadas a operações originadas por meio da Plataforma, Você
            poderá entrar em contato com a Plataforma através de
            (contato@Veriza.com.br), apresentando requerimento fundamentado por
            escrito e, quando for o caso, acompanhado da respectiva decisão
            judicial.
          </p>
          <p>
            <strong>Outros esclarecimentos: </strong>
            <br></br>a Plataforma está à sua disposição para eventuais
            esclarecimentos através do e-mail contato@Veriza.com.br.
          </p>
          <p>
            Maiores informações a respeito do SCR, incluindo os procedimentos
            necessários para se obter acesso ao SCR, bem como sua utilização,
            também podem ser obtidas pela Central de Atendimento do BACEN, pelo
            correio eletrônico BC – Fale conosco, ou no endereço eletrônico
            http://www.bcb.gov.br/.
          </p>
          <p>
            <strong>Conteúdo da Plataforma e Propriedade Intelectual:</strong>
            <br></br> o Tomador concorda em não reproduzir, duplicar, copiar,
            vender, revender ou explorar com ou sem finalidade comercial
            qualquer parte das informações e conteúdos da Plataforma (incluindo,
            sem limitação, a Proposta de Crédito), bem como se obriga a utilizar
            todo o conteúdo da Plataforma de forma lícita, sendo vedada a
            reprodução, distribuição, transformação, comercialização ou
            modificação do conteúdo, sem a prévia e expressa autorização da
            Veriza, bem como fica vedado o fornecimento de login e senha para
            terceiros.
          </p>
          <p>
            Sem prejuízo de outras medidas que entender cabíveis, a Veriza
            poderá advertir,suspender ou cancelar, temporária ou definitivamente
            o cadastro do Tomador, a qualquer tempo, iniciando as ações legais
            cabíveis e/ou suspendendo a execução destes Termos se o Tomador não
            cumprir qualquer dispositivo aqui previsto e demais políticas da
            Veriza.
          </p>
          <p>
            Ao se cadastrar na Plataforma, o Tomador concede à Veriza e seus
            parceiros, uma licença gratuita, perpétua e irrevogável, para o uso
            de todo e qualquer conteúdo, foto ou imagem postado na Plataforma,
            de acordo com os fins estabelecidos nestes Termos e na Política de
            Privacidade da Veriza.
          </p>
          <p>
            <strong>Responsabilidades:</strong>
            <br></br> O TOMADOR RECONHECE EXPRESSAMENTE QUE A VERIZA NÃO É
            RESPONSÁVEL POR NENHUM PROBLEMA TÉCNICO E/OU OPERACIONAL, INCLUINDO
            FALHAS, PERDA DE INFORMAÇÃO, SUSPENSÕES, INTERRUPÇÕES E QUALQUER
            OUTRO MAU DESEMPENHO DA PLATAFORMA, NEM OFERECE NEM NUNCA OFERECEU
            QUALQUER TIPO DE GARANTIA AOS TOMADORES OU A QUALQUER TERCEIRO A
            RESPEITO DA UTILIZAÇÃO DA PLATAFORMA E/OU APROVAÇÃO E CONCESSÃO DO
            CRÉDITO POR PARTE DAS INSTITUIÇÕES FINANCEIRAS PARCEIRAS.
          </p>
          <p>
            EM QUALQUER HIPÓTESE, A RESPONSABILIDADE MÁXIMA DA VERIZA DECORRENTE
            DESTES TERMOS SERÁ LIMITADA AOS DANOS DIRETOS COMPROVADAMENTE
            SOFRIDOS PELO TOMADOR EM RAZÃO DE QUALQUER DESCUMPRIMENTO DAS
            OBRIGAÇÕES DA VERIZA, LIMITADOSAO MAIOR VALOR ENTRE O VALOR DEVIDO
            OU PAGO PELO TOMADOR À VERIZA CUJA RELAÇÃO TENHA CAUSADO O DANO E R$
            2.500,00 (DOIS MILE QUINHENTOS REAIS), SENDO EXCLUÍDOS TODOS E
            QUAISQUERPLEITOS POR DANOS INDIRETOS (INCLUINDO POR LUCROS
            CESSANTESE PERDA DE DADOS OU OPORTUNIDADE DE NEGÓCIOS) SOFRIDOS PELO
            TOMADOR OU POR QUALQUER TERCEIRO DECORRENTES DO USO OU FUNCIONAMENTO
            DA PLATAFORMA.
          </p>
          <p>
            <strong>Pagamento: </strong>
            <br></br>O cadastro na Plataforma, para fins de submissão do Pedido
            de Empréstimo é gratuito. De todo modo, a Veriza se reserva o
            direito de estabelecer tarifa para prestação de seus serviços a
            qualquer momento, mediante notificação prévia de 30 (trinta) dias.
            Após esse período, sua contínua utilização da Plataforma significará
            sua aceitação à nova política comercial da Veriza.
          </p>
          <p>
            <strong>
              Política de Privacidade e Política de Avaliação Crédito:
            </strong>
            <br></br>
            Mediante a aceitação destes Termos de Uso, o Tomador expressamente
            concorda com aPolítica de Privacidadee com aPolítica Avaliação de
            Créditoda Veriza,permitindo assim o uso de seus dados de cadastro
            pela Veriza exclusivamente para os fins descritos nas referidas
            políticas da Veriza.
          </p>
          <p>
            A VERIZA NÃO PODERÁ SER RESPONSABILIZADA, CIVIL OU CRIMINALMENTE,
            NEM TAMPOUCO ESTARÁ SUJEITA À INDENIZAÇÃO OU RESSARCIMENTO EM
            DECORRÊNCIA DAS AVALIAÇÕES DE CRÉDITO REALIZADAS PELA PLATAFORMA.
          </p>
          <p>
            <strong>Disposições Gerais:</strong>
            <br></br> Ao se cadastrar na Plataforma, Você autoriza que a Veriza
            entre em contato com você por meio de e-mails, SMS, mensagens por
            WhatsApp, correspondências e ligações telefônicas. Quaisquer
            dúvidas, críticas, sugestões e/ou reclamações relacionadas à
            utilização da plataforma poderão ser feitas pelo e-mail
            ouvidoria@Veriza.com.br.
          </p>
          <p>
            <strong>Aceitação:</strong>
            <br></br> A utilização da Plataforma como Tomador pressupõe o
            conhecimento e a aceitação integral destes Termos.
          </p>
          <p>
            CASO VOCÊ NÃO ACEITE OU NÃO CONCORDE COM ESTES TERMOS, NÃO CLIQUE EM
            “ACEITO OS TERMOS DE CADASTRO”
          </p>
          <p>
            CASO VOCÊ CONCORDE E ACEITE ESTES TERMOS, FAVOR MANIFESTAR SUA
            CONCORDÂNCIA POR VIA ELETRÔNICA CLICANDO EM “ACEITO OS TERMOS DE
            CADASTRO”.
          </p>
        </Text>
      </Content>
    </Container>
  )
}
